<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>

        <div>
            <div class="container-bg" style="margin-top: 1%">
                <div class="container-fg">
                    <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                        RMA/Upgrade Quotes
                    </h1>
                    <p
                        >Review information for any quotes created in the last
                        30 days. Any quotes created 30 days ago will be closed
                        automatically.</p
                    >
                    <v-data-table
                        :server-items-length="serverItemsLength"
                        :loading="serverItemsLoading"
                        :items="displayedTableItems"
                        :options.sync="options"
                        :footer-props="footerOptions"
                        item-key="uid"
                        :headers="headers"
                        single-select
                        dense
                        show-select
                        :items-per-page="5"
                        @update:options="lookForMoreServerItems"
                        @item-selected="
                            selected = $event.value ? $event.item : null
                        "
                    >
                        <template v-slot:item.total="{ value }">
                            ${{ value.toFixed(2) }}
                        </template>
                        <template v-slot:item.createdAt="{ value }">
                            {{ new Date(value).toLocaleString() }}
                        </template>
                        <template v-slot:item.problem="{ item }">
                            {{ item.externalResource.rmaInput.primaryFault }} -
                            {{ item.externalResource.rmaInput.problem }}
                        </template>
                        <template v-slot:footer.prepend>
                            <base-btn
                                :disabled="!selected"
                                small
                                outlined
                                @click="continueRma"
                                >Continue</base-btn
                            >
                        </template>
                    </v-data-table>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { relayPagination } from '@/mixins/relay_pagination.js'
import { navLinks } from '../navigation.js'
import { toString } from '@/utils'
import { gql } from '@apollo/client'

// Make a query to call in the RMA information in the data() return statement.

export default {
    metaInfo: { title: `Your Account`, subTitle: `Account Settings` },
    name: `MyQuotes`,
    mixins: [relayPagination('savedQuoteConnection')],
    apollo: {
        savedQuoteConnection: {
            query: gql`
                query savedQuoteConnection {
                    savedQuoteConnection(service: "rma-manager") {
                        totalCount
                        pageInfo {
                            hasPreviousPage
                            hasNextPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                uid
                                customerSaved
                                service
                                reference
                                externalResource
                                shipping {
                                    address {
                                        line1
                                        line2
                                        city
                                        state
                                        country
                                        postalCode
                                        international
                                        apo
                                    }
                                    contact {
                                        emailAddress
                                        phoneNumber
                                        firstName
                                        lastName
                                        companyName
                                        fullName
                                    }
                                }
                                billing {
                                    address {
                                        line1
                                        line2
                                        city
                                        state
                                        country
                                        postalCode
                                        international
                                        apo
                                    }
                                    contact {
                                        emailAddress
                                        phoneNumber
                                        firstName
                                        lastName
                                        companyName
                                        fullName
                                    }
                                }
                                items {
                                    uid
                                    name
                                    price
                                    quantity
                                    tax
                                    total
                                }
                                amount {
                                    freight
                                    handling
                                    discount
                                    tax
                                }
                                redirects {
                                    completed
                                    canceled
                                }
                                details {
                                    payment {
                                        uid
                                        source
                                        url
                                        status
                                        transaction
                                        adminStatus
                                        possibleStatusList
                                    }
                                    tax {
                                        uid
                                        source
                                        status
                                    }
                                }
                                createdAt
                                updatedAt
                                customerPo {
                                    poNumber
                                    poFileId
                                }
                                createdBy
                                subtotal
                                total
                            }
                        }
                    }
                }
            `
        }
    },
    data() {
        return {
            panel: 0,
            links: navLinks,
            selected: null,
            headers: [
                {
                    text: 'Quote #',
                    align: 'start',
                    sortable: false,
                    value: 'uid'
                },
                {
                    text: 'Serial Number',
                    value: 'externalResource.serial',
                    sortable: false
                },
                { text: 'Date Created', value: 'createdAt', sortable: false },
                {
                    text: 'Problem Reported',
                    value: 'externalResource.rmaInput.problem',
                    sortable: false
                },
                { text: 'Quote Total', value: 'total', sortable: false }
            ],
            headers2: [
                {
                    text: 'Quote No',
                    align: 'start',
                    sortable: false,
                    value: 'uid'
                },
                { text: 'Type', value: 'rmaType', sortable: false },
                { text: 'Serial', value: 'unit.serial', sortable: false },
                { text: 'Date Created', value: 'createdAt', sortable: false },
                {
                    text: 'Quote Fee',
                    value: 'initialPricing[0].price',
                    sortable: false
                },
                { text: '', value: 'payQuote', sortable: false }
            ],
            footerOptions: {
                itemsPerPageOptions: [10, 25, 50]
            },
            options: {
                sortBy: [`createdAt`],
                sortDesc: [true],
                itemsPerPage: 10,
                page: 1
            }
        }
    },
    methods: {
        continueRma() {
            const routeName =
                this.selected.externalResource.rmaInput.primaryFault === 'UPGRADE' ? 'upgrade-device' : 'create-rma'
            const toPage = routeName.includes('upgrade') ? 5 : 4
            this.$router.push({
                name: routeName,
                params: {
                    serial: this.selected.externalResource.serial,
                    toPage,
                    quoteData: this.selected
                },
                query: {
                    quoteId: this.selected.uid,
                    prefill: toString(this.selected.externalResource)
                }
            })
        },
        payQuote() {
            const routeName =
                this.selected.externalResource.rmaInput.primaryFault === 'UPGRADE' ? 'upgrade-device' : 'create-rma'
            const toPage = routeName.includes('upgrade') ? 5 : 4
            this.$router.push({
                name: routeName,
                params: { toPage, quoteData: this.sampleQuotes[0] }
            })
        }
    }
}
</script>
